import { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { questionModal, successModal } from "../../Components/CustomModal";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";
import BASEURL from "../../Config/global";
import { AddImageComponent } from "./ImageComponent";
import CustomLoader from "../../Components/CustomLoader";

const AddFeaturedMixCategory = () => {

  const navigate = useNavigate();

  const [inputValue, setinputValue] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [soundImage, setSoundImage] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleChange = (event) => {
    setinputValue(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file === null || file === "undefined") {
      setSoundImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSoundImage(reader.result);
        setSelectedImage(reader.result);

        console.log(reader)
      };
    }
  };

  const handleRemoveImage = (event) => {
    setSoundImage(null)
    setSelectedImage(null)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue) {
      questionModal
        .fire({
          title: "Do you want to add the categories?",
          confirmButtonText: "Add",
        })
        .then((result) => {
          if (result.isConfirmed) {
            setLoader(true);
            postData();
          }
        });
    }
  };

  const postData = async () => {
    try {
      const body = JSON.stringify({
        "name": inputValue,
        "banner": selectedImage
      });
      const header = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await axios.post(`${BASEURL}/api/sounds/mixFeatureCategories`, body, header);
      setLoader(false);
      if (!response.data.error) {
        successModal.fire({
          text: 'Featured Mix Category Added Successfully',
          confirmButtonText: "Continue",
        });
        navigate("/category-management");
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Add Featured Mix Category
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-5">
                <div className="col-lg-6 mb-2">
                  <form>
                    <div className="inputWrapper">
                      <label className="mainLabel">Category*</label>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="text"
                          placeholder="Enter Category"
                          required=""
                          name="category"
                          className="mainInput"
                          value={inputValue.category}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <AddImageComponent
                  soundImage={soundImage}
                  handleImageChange={handleImageChange}
                  handleRemoveImage={handleRemoveImage}
                />
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <CustomButton
                    type="button"
                    variant="primaryButton"
                    text="Add"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default AddFeaturedMixCategory;
