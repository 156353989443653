import React, { useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CustomTable from '../../Components/CustomTable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import BASEURL from '../../Config/global';
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import { questionModal, successModal } from '../../Components/CustomModal';
import CustomLoader from '../../Components/CustomLoader';
import './style.css';

const FeaturedMix = () => {

    const [config, setConfig] = useState({ error: false, msg: '', value: '', loader: false });
    const [formData, setFormData] = useState({});
    const [categoryModalConfig, setCategoryModalConfig] = useState({ error: false, msg: '', value: '', loader: false });
    const [soundMixList, setSoundMixList] = useState([]);
    const [categoryMixList, setCategoryMixList] = useState([]);
    const [subCategoryMixList, setSubCategoryMixList] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const header = [
        {
            key: "ids",
            title: "Action",
        },
        {
            key: "id",
            title: "Mix ID",
        },
        {
            key: "title",
            title: "Title",
        },
    ];

    const onSortEnd = (oldIndex, newIndex) => {
        let updatedArray = arrayMove(soundMixList, oldIndex, newIndex);
        setSoundMixList(updatedArray);
    };

    const getFeaturedListByEmail = async (event) => {
        // fetch data from API with email
        // update state with fetched data
        try {
            event.preventDefault();
            setConfig({ ...config, loader: true, error: false });
            const body = {
                email: config?.value
            };
            // https://backend.dev.relax.scofa.com/api/sounds/user_mix'
            const response = await axios.post(`${BASEURL}/api/sounds/user_mix`, body);
            setConfig({ ...config, loader: false });
            if (response.data.count === 0 && response.data.data.length === 0) {
                setSoundMixList([])
                setConfig({ ...config, error: true, msg: "No Record Found" });
            } else {
                setSoundMixList(response?.data?.data)
            }
        } catch (error) {
            setSoundMixList([])
            setConfig({ ...config, error: true, msg: "No Record Found", loader: false });
        }
    }

    // handle the modal window and set the mixid.
    const openCategoryModal = (mixer_id) => {
        setShowCategoryModal(true);
        setFormData({ ...formData, "mixer_id": mixer_id });
        getMixConfigurationById(mixer_id);
        getAllMixCategories();
    }

    const getMixConfigurationById = async (id) => {
        // fetch data from API with email
        // update state with fetched data
        try {
            setCategoryModalConfig({ ...config, loader: true, error: false });
            // http://backend.dev.relax.scofa.com/api/sounds/set-mixer-category/?mixer_id=2454'
            const response = await axios.get(`${BASEURL}/api/sounds/set-mixer-category/?mixer_id=${id}`);
            if (!response.data.error) {
                setCategoryModalConfig({ ...config, loader: false, error: false });
                setFormData(response?.data?.data);
                if (response?.data?.data?.parent_category_id) {
                    getMixSubCategoriesById(response?.data?.data?.parent_category_id);
                }
            }
        } catch (error) {
            setCategoryModalConfig({ ...config, error: true, msg: error.message, loader: false });
        }
    }

    const getAllMixCategories = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/sounds/mixFeatureCategories`);
            setCategoryMixList(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getMixSubCategoriesById = async (id) => {
        try {
            const response = await axios.get(`${BASEURL}/api/sounds/mixsubcategories/${id}`);
            setSubCategoryMixList(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const updateMixSortingPositionConfirmation = async () => {
        // fetch data from API with email
        // update state with fetched data
        try {
            questionModal.fire({
                title: `Do you want to update this mix list?`,
                confirmButtonText: "Update",
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true);
                    updateMixSortingPosition();
                }
            });
        } catch (error) {
            console.log(error)
            // setCategoryModalConfig({ ...config, msg: error.message, loader: false });
        }
    }

    const updateMixSortingPosition = async () => {
        // fetch data from API with email
        // update state with fetched data
        try {
            let body = {
                user_id: soundMixList[0]['user'],
                data: soundMixList?.map((item, index) => ({ mixid: item.id, position: index + 1 }))
            }
            // setCategoryModalConfig({ ...config, loader: true });
            // https://backend.dev.relax.scofa.com/api/sounds/update_mix_position/
            const response = await axios.patch(`${BASEURL}/api/sounds/update_mix_position/`, body);
            setLoader(false);
            if (!response.data.error && response.data.status === 200) {
                successModal.fire({
                    text: `Featured Mix List Updated Successfully`,
                    confirmButtonText: "Continue",
                });
                setSoundMixList([]);
                setConfig({ ...config, loader: false, value: '' });
            }
        } catch (error) {
            setLoader(false);
            setConfig({ ...config, loader: false });
            console.log(error);
            // setCategoryModalConfig({ ...config, msg: error.message, loader: false });
        }
    }

    const addCategoryAndSubCategory = async () => {
        // fetch data from API with email
        // update state with fetched data
        try {
            if (formData?.parent_category_id || formData?.sub_category_id || formData?.is_featured) {
                setCategoryModalConfig({ ...config, error: false });
                setLoader(true);
                let body = {
                    "mixer_id": formData?.id,
                    "parent_category_id": formData?.parent_category_id,
                    "sub_category_id": formData?.sub_category_id,
                    "is_featured": formData?.is_featured
                }
                // http://backend.dev.relax.scofa.com/api/sounds/set-mixer-category/
                const response = await axios.patch(`${BASEURL}/api/sounds/set-mixer-category/`, body);
                // console.log(response.data)
                setLoader(false);
                if (!response.data.error && response.data.status === 200) {
                    successModal.fire({
                        text: `Featured Mix Categories Added`,
                        confirmButtonText: "Continue",
                    });
                    setConfig({ ...config, loader: false });
                    setCategoryModalConfig({ error: false, msg: '', value: '', loader: false });
                    setShowCategoryModal(false);
                    setFormData({});
                }
            } else {
                setCategoryModalConfig({ ...config, error: true, msg: 'No Changes Exist!' });
            }
        } catch (error) {
            setLoader(false);
            setCategoryModalConfig({ ...config, msg: error.message, error: true });
            console.log(error);
        }
    }

    return (
        <>

            <DashboardLayout>
                <div className='container-fluid'>
                    {/* TOP ROW / SEARCH BAR */}
                    <div className="row mb-3">
                        <div className="col-8">
                            <h2 className="mainTitle">Featured Mix</h2>
                        </div>
                        <div className="col-4 text-end">
                            <button type="button"
                                disabled={soundMixList.length > 0 ? false : true}
                                className={`customButton ${soundMixList.length > 0 ? 'primaryButton' : ''}`}
                                onClick={updateMixSortingPositionConfirmation}>
                                Update Mix
                            </button>
                        </div>
                        <div className="col-12 my-2">
                            <form autoComplete="off" onSubmit={getFeaturedListByEmail} className="searchWrapper d-flex m-auto" style={{ maxWidth: '500px' }}>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    name="search"
                                    className="filterInput searchInput w-100"
                                    autoComplete='off'
                                    value={config?.value}
                                    onChange={(event) => {
                                        setConfig({ ...config, value: event.target.value });
                                    }}
                                />
                                <button type='submit' className="searchButton notButton">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </form>
                        </div>
                    </div>
                    {/* SORTABLE */}
                    <SortableList
                        onSortEnd={onSortEnd}
                        onDrag={(e) => { console.log(e) }}
                        className="list"
                        draggedItemClassName="dragged"
                    >
                        <CustomTable headers={header}>
                            <tbody>
                                {(!config.loader && soundMixList.length > 0) ? (
                                    soundMixList?.map((item) => (
                                        <SortableItem key={item.id}>
                                            <tr>
                                                <td width={150}>
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => openCategoryModal(item.id)} />
                                                    {/* <input type='checkbox' onChange={() => handlCategoryModal(item.id)} /> */}
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                            </tr>
                                        </SortableItem>
                                    ))
                                ) : (
                                    <tr>
                                        {config.error && <td colSpan="3" className="text-center">{config.msg}</td>}
                                        {config.loader && <td colSpan="3" className="text-center"> <div className="spinner-border" role="status"></div></td>}
                                    </tr>
                                )}
                            </tbody>
                        </CustomTable>

                    </SortableList>
                </div>
            </DashboardLayout>

            {/* Show Category Modal */}
            <Modal
                show={showCategoryModal}
                centered
                className="swap-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="w-100 d-flex flex-column align-items-center">
                        <div className="d-flex justify-content-between w-100 ">
                            <h2 className="mainTitle">Add Category</h2>
                            <div className="text-end ms-auto">
                                <button
                                    className="closeButton notButton ms-auto"
                                    onClick={() => {
                                        setShowCategoryModal(false);
                                        setFormData({});
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="cross-icon"
                                        color="white"
                                    />
                                </button>
                            </div>
                        </div>
                        <br />

                        <div className="row w-100">
                            <div className='col-12 mb-3'>
                                <p className="mainLabel mt-2">Select Category</p>
                                <select
                                    name="parent_category_id"
                                    id="parent_category_id"
                                    className="mainInput w-100"
                                    value={formData?.parent_category_id || ""}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setFormData({
                                            ...formData, [name]: Number(value)
                                        });
                                        getMixSubCategoriesById(Number(value));
                                    }}
                                >
                                    <option value={null}> Select </option>
                                    {categoryMixList.length > 0 && categoryMixList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-12 mb-3'>
                                <p className="mainLabel mt-2">Select Sub Category</p>
                                <select
                                    name="sub_category_id"
                                    id="sub_category_id"
                                    className="mainInput w-100"
                                    value={formData?.sub_category_id || ""}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setFormData({
                                            ...formData, [name]: Number(value)
                                        });
                                    }}
                                >
                                    <option value={null}> Select </option>
                                    {subCategoryMixList.length > 0 && subCategoryMixList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-12 mb-2">
                                <div className='mt-2'>
                                    <input
                                        type="checkbox"
                                        name="featured"
                                        id="featured"
                                        checked={formData.is_featured ? true : false}
                                        onChange={() => {
                                            setFormData({
                                                ...formData, 'is_featured': !formData.is_featured
                                            });
                                        }}
                                    />
                                    <label htmlFor="featured" className="mainLabel ms-1">
                                        Featured
                                    </label>
                                </div>
                            </div>
                        </div>
                        {categoryModalConfig?.error && <small className="text-danger mt-3">{categoryModalConfig.msg}</small>}
                        <div className="pt-5 d-flex gap-3 justify-content-center mb-3">
                            <button
                                type='button'
                                style={{ backgroundColor: '#ffffff1f' }}
                                className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0'
                                onClick={addCategoryAndSubCategory}
                            >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Loader */}
            {loader && <div className='featured-loader'><CustomLoader /></div>}
        </>
    )
}

export default FeaturedMix